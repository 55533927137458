<template>
    <div class="member-chart">
        <div id="rechargeChartBox" v-show="yRechargeList && yRechargeList.length"></div>
        <p class="no-data" v-show="!yRechargeList">暂无数据</p>
    </div>
</template>

<script>
	// import echarts from 'echarts'
	export default {
		name:"recharge-chart",
		props:{
			chartData:Object,
			timeParams:Object,
		},
		data(){
			return{
				xList:null,
				yRechargeList:null,
				yGiftList:null,
			}
		},
		watch:{
			chartData:{
				handler(){
					if(!this.chartData){
						return
					}
					this.resolveChartData()
				},
				deep:true,
				immediate:true,
			},
			timeParams:{
				handler(newVal,oldVal){
					if(! newVal){
						return
					}
					if(! ! oldVal && newVal.startTime === oldVal.startTime){
						return
					}
					this.resolveChartData()
				},
				deep:true,
				immediate:true,
			},
		},
		methods:{
			/**
			 * 处理图表数据
			 */
			resolveChartData(){
				let {chartData} = this
				//实际会员充值
				if(chartData&&chartData.member_recharge_list){
					let xList = []
					let yRechargeList = []
					let yRechargeMaxDigit = 0;
					let yRechargeDigit = 0
					for(let item of chartData.member_recharge_list) {
						for(let key in item){
							let yRechargeDigit = parseInt(item[key],10).length
							yRechargeMaxDigit = yRechargeMaxDigit > yRechargeDigit ? yRechargeMaxDigit : yRechargeDigit
							xList.push(key)
							yRechargeList.push(item[key])
						}
					}
					yRechargeList.forEach(value => value = value/Math.pow(10,yRechargeDigit))

					this.xList = xList
					this.yRechargeList = yRechargeList
				}

				//会员卡赠送
				if(chartData&&chartData.recharge_gift_list){
					let yGiftList = []
					let yGiftDigit = 0;
					let yGiftMaxDigit = 0;
					for(let item of chartData.recharge_gift_list) {
						for(let key in item){
							let yGiftDigit = parseInt(item[key],10).length
							yGiftMaxDigit = yGiftMaxDigit > yGiftDigit ? yGiftMaxDigit : yGiftDigit
							yGiftList.push(item[key])
						}
					}
					yGiftList.forEach(value => value = value/Math.pow(10,yGiftDigit))
					this.yGiftList = yGiftList
				}
				this.renderChart()
			},
			/**
			 * 配置营业分析走势图
			 */
			renderChart(){
				let myChart = this.$echarts.init(document.getElementById('rechargeChartBox'));
				let {yRechargeList,yGiftList,xList} = this
				var color = '#0066ff'
				let tooltipBack = 'rgba(50,50,50,0.6)'
				let option = {
					theme:'light',
					grid:{
						left:'5%',
						right:'6%',
						bottom:'3%',
						top:'10%',
						containLabel:true
					},
					xAxis:{
						type:'category',
						boundaryGap:false,
						data:xList,
						axisTick:{
							alignWithLabel:true
						},
						axisLine:{
							show:false
						},
						axisTick:{
							show:false,
						},
						axisPointer:{
							snap:true,
						},
					},
					yAxis:{
						type:'value',
						splitNumber:5,
						axisLine:{
							lineStyle:{
								color:'#dfdfdf'
							}
						},
						axisTick:{
							show:false,
						},
						axisLabel:{
							inside:true,
							color:'#333333'
						},
					},
					tooltip:{
						trigger:'axis',
            textStyle: {
               color: "#FFFFFF" //设置文字颜色
            },
						confine: true,
						backgroundColor:tooltipBack,
						formatter:function(params){
							let {axisValue} = params[0]
							let time = axisValue.split('.')
							let dateTime = time[0]+'月'+time[1]+'日'
							let relVal = ''
							for (let i = 0; i < params.length; i++) {
								relVal +=
										// '<div class="circle" ><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background:'+params[i].color+'"></span>'+ params[i].seriesName + ' : ' + parseFloat(params[i].value).toLocaleString('en-US')+"元</div>";
										'<div class="circle" ><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background:'+params[i].color+'"></span>'+ params[i].seriesName + ' : ' + params[i].value +"元</div>";

							}
							return "日期："+dateTime+"<br/>"+relVal;
						}
					},

					series:[
						{
							name:'实际会员充值',
							data:yRechargeList,
							type:'line',
							smooth:true,
							barWidth:14,
							shadowColor: 'rgba(0, 0, 0, 0.5)',
							shadowBlur: 10,
							itemStyle:{
								normal:{
									color:'#397eff',
									lineStyle:{
										color
									},
									areaStyle:{
										color:"#d4f0ff",
									}
								},
							},
						},{
							name:'会员卡赠送',
							data:yGiftList,
							type:'line',
							color:'#ff9500',
							smooth:true,
							barWidth:14,
							itemStyle:{
								normal:{
									color:'#ff9500',
									lineStyle:{
										color:'#ff9500'
									},
									areaStyle:{
										color:"#f6dbb1",
									}
								},
							},
						},

					],
				}

				myChart.setOption(option);
			}
		},
	}
</script>
<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .member-chart{
        background:#FFFFFF;
    }

    .chart-title{
        font-size:26px;
        line-height:60px;
        height:60px;
        padding:0 0 0 25px;
    }

    #rechargeChartBox{
        height:460px;
        width:100vw;
    }
    .no-data{
        background:#FFFFFF;
        display:flex;
        align-items:center;
        justify-content:center;
        color:#666666;
        height:500px;
    }
</style>